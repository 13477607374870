import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  title: any;
  content = '';
  okText = 'common_ok';
  options: any;

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  close() {
    this.modal.dismiss();
  }

  onAgree() {
    this.modal.dismiss();
  }

}
