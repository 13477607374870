import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'terms-conditions-login',
    templateUrl: 'terms-conditions-login.component.html',
    styleUrls: ['terms-conditions-login.component.scss']
})

export class TermsConditionsLoginComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}