import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { ScriptLoaderService } from '../../../helpers/script-loaders.service';

// declare function callCSONow(): any;

@Component({
    selector: 'app-cso-form',
    templateUrl: 'cso-form.component.html',
    styleUrls: ['cso-form.component.scss']
})

export class CSOFormComponent implements OnInit, AfterViewInit {

    @Output() onSubmit = new EventEmitter();

    @ViewChild('inputCompleted') inputCompletedRef: ElementRef;

    clientId: string = 'FC0B2642-E386-11E9-8512-12C4BF96CE94';

    private _surveyId: string;

    constructor(private _scriptLoaderService: ScriptLoaderService) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this._scriptLoaderService.loadScripts('body', [
            'https://d3op16id4dloxg.cloudfront.net/CSOWrapperAjax2.js',
            'assets/js/cso.js'
        ]);
    }

    populateData(surveyId, CID, statusFlag, panelistID): void {
        this._surveyId = surveyId;

        (document.getElementById('Survey') as HTMLInputElement).value = surveyId;
        (document.getElementById('CID') as HTMLInputElement).value = CID;
        (document.getElementById('StatusFlag') as HTMLInputElement).value = statusFlag;
        (document.getElementById('PanelistID') as HTMLInputElement).value = panelistID;
    }

    handleSubmit(): void {
      this.onSubmit.emit({ surveyId: this._surveyId, });
      this.inputCompletedRef.nativeElement.value = 0;

      // setTimeout(() => {
      //   const value = this.inputCompletedRef.nativeElement.value;
      //   const response = (document.getElementById("AckFlag") as HTMLInputElement).value;
      //   this.onSubmit.emit({
      //     surveyId: this._surveyId,
      //     relevantResponse: value ? response : null
      //   });}, 5000
      // );

        // trigger call RVID
        // callCSONow();
    }
}
