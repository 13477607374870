import { DOCUMENT, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConsts } from '../shared/_constant/AppConsts';

export type Decorator = (object: any, field: string) => boolean;

@Injectable({ providedIn: 'root' })
export class Utilities {
  req: RequestHelper;

  constructor(
    private _httpClient: HttpClient,
    @Inject(DOCUMENT) private _document: any,
    private _location: Location
  ) {    
    this.req = new RequestHelper(_httpClient);
  }

  generateAbsoluteUrl(url: string): string {
    return `${this._document.location.origin}${this._location.prepareExternalUrl(url)}`;
  }

  generateDeviceToken(): string {
    const deviceToken = localStorage.getItem('device_token');

    if (deviceToken) {
      return deviceToken;
    } else {
      let newDeviceToken = "browser";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 50; i++) {
        newDeviceToken += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      localStorage.setItem('device_token', newDeviceToken);
      return newDeviceToken;
    }
  }

  public getHeaderTitle(el: ElementRef, id: string): string {
    return el.nativeElement.querySelector(`#${id}`).textContent;
  }
}

class RequestHelper {

  baseApiUrl: string = AppConsts.remoteServiceBaseUrl;

  constructor(private _httpClient: HttpClient) { }

  get(api: string): Observable<any> {
    return (this._httpClient.get(this.baseApiUrl + api));
  }

  post(api: string, data: any, options?: any): Observable<any> {
    var a = api;
    var b = data;
    var c = options;
    /* if(options != undefined){
      return (this._httpClient.post(this.baseApiUrl + api, data, options));
    }else{ */
      return (this._httpClient.post(this.baseApiUrl + api, data));
    //}
  }

  delete(api: string, options?: any): Observable<any> {
    return (this._httpClient.delete(this.baseApiUrl + api, options));
  }
}
