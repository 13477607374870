import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Utilities } from '../../helpers/utilities';

import { TwoFAUrls } from '../constants/two-f-a-url.const';
import { TwoFACodeResponse, TwoFAChangeStatusRequest, TwoFAVerifyPasswordRequest, TwoFALoginPayload } from '../models/two-f-a.model';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class TwoFAService {
  constructor(private _u: Utilities, private _domSanitizer: DomSanitizer) {}

  verifyPassword(payload: TwoFAVerifyPasswordRequest): Observable<boolean> {
    return this._u.req.post(TwoFAUrls.verifyPassword, payload).pipe(map(response => !!response.data));
  }

  get2FACode(): Observable<TwoFACodeResponse> {
    return this._u.req.post(TwoFAUrls.getTwoFACode, {})
      .pipe(map(response => new TwoFACodeResponse(response.data, this._domSanitizer)));
  }

  update2FAStatus(payload: TwoFAChangeStatusRequest) {
    return this._u.req.post(TwoFAUrls.updateTwoFAStatus, payload).pipe(map(response => response.data));
  }

  cancel2FA() {
    return this._u.req.post(TwoFAUrls.cancel2FA, {});
  }

  verify2FACode(code: string) {
    return this._u.req.post(TwoFAUrls.verify2FACode, {
      'verify_code': code
    });
  }

  loginWith2FACode(payload: TwoFALoginPayload) {
    return this._u.req.post(TwoFAUrls.loginWith2FACode, payload);
  }
}
