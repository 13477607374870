import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as $ from 'jquery';
import { StaticPagesService } from '../../services/static-pages.service';

@Component({
  selector: 'deep-link-redirect-page',
  templateUrl: 'deep-link-redirect-page.html',
  styleUrls: ['deep-link-redirect-page.scss']
})

export class RedirectPageComponent implements OnInit, AfterViewInit {

  message = "YOU COMPLETED THE SURVEY!";
  isWebView : boolean;

  constructor() {}

  ngOnInit() {
    this.isWebView = navigator.userAgent.includes('wv') || navigator.userAgent.includes('webview')
    this.runApp();
  }

  ngAfterViewInit() {
    this.runApp();
  }

  runApp() {
    this.isWebView = navigator.userAgent.includes('wv') || navigator.userAgent.includes('webview');
    const currentURL = window.location.href;

    const searchParams = new URL(this._correctUrlQueryParams(currentURL)).searchParams;

    const surveyId = searchParams.get('abpid') || '';
    const uuid = (searchParams.get('abuid') || searchParams.get('uid')) || '';
    const status = searchParams.get('abstatus') || '';

    // const surveyId = this._getValueByKey(currentURL, 'abpid');
    // const uuid = this._getValueByKey(currentURL, 'abuid') || this._getValueByKey(currentURL, 'uid');
    // const status = this._getValueByKey(currentURL, 'abstatus');


    var DeepUrliOS = "com.sts.survey://surveyComplete?status_survey=" + status + "&survey_id=" + surveyId + "&uuid=" + uuid;
    var DeepUrlAndroid = "apiarybuzz://complete?status_survey=" + status + "&survey_id=" + surveyId + "&uuid=" + uuid;
    var DeepUrlWeb: string = "";

    if (environment.development) {
      DeepUrlWeb = "https://staging.apiarybuzz.com/survey/completion?status_survey=" + status + "&survey_id=" + surveyId + '&uuid=' + uuid;
    } else if (environment.production) {
      DeepUrlWeb = "https://apiarybuzz.com/survey/completion?status_survey=" + status + "&survey_id=" + surveyId + "&uuid=" + uuid;
    } else if (environment.staging) {
      DeepUrlWeb = "https://staging.apiarybuzz.com/survey/completion?status_survey=" + status + "&survey_id=" + surveyId + '&uuid=' + uuid;
    }

    var userAgentString = navigator.userAgent.toLowerCase();
    if (userAgentString.indexOf("iphone") > -1 || userAgentString.indexOf("ipod") > -1 || userAgentString.indexOf("ipad") > -1) {
      if (/safari/.test(userAgentString) && !/chrome/.test(userAgentString)) {
        return window.location.href = DeepUrlWeb;
      }
      window.location.href = DeepUrliOS;
    } else if (navigator.userAgent.match(/android/i)) {
      if (!this.isWebView) {
        return window.location.href = DeepUrlWeb;
      } else {
        window.location.href = DeepUrlAndroid;
        window.open(DeepUrlAndroid);
      }
    }
    else if (userAgentString.indexOf("mac") > -1) {
      window.location.href = DeepUrlWeb;
    } else {
      window.location.href = DeepUrlWeb;
    }
  }

  private _correctUrlQueryParams(url: string) {
    if (!url) {
      return '';
    }
    let correctUrl = url.replace('_abpid', '&abpid');
    correctUrl = correctUrl.replace('_abuid', '&abuid');
    correctUrl = correctUrl.replace('_uid', '&uid');
    correctUrl = correctUrl.replace('_abstatus', '&abstatus');
    correctUrl = correctUrl.replace('abpid-', 'abpid=');
    correctUrl = correctUrl.replace('abuid-', 'abuid=');
    correctUrl = correctUrl.replace('uid-', 'uid=');
    correctUrl = correctUrl.replace('abstatus-', 'abstatus=');
    return correctUrl
  }

  // private _getValueByKey(url: string, key: string): string {
  //   console.log(url, key);
  //   let value = '';
  //   const keyIndex = url.indexOf(key);
  //   if (keyIndex >= 0) {
  //     // get index from found key index + found key length + length of character '=' or '-'
  //     const fromIndex = keyIndex + key.length + 1;
  //     if (fromIndex > url.length) {
  //       return '';
  //     }
  //     const restURL = url.substr(fromIndex);
  //     const nextIndex = restURL.includes('&') ? restURL.indexOf('&') : restURL.indexOf('_');
  //     return nextIndex >= 0 ? restURL.substr(0, nextIndex) : restURL;
  //   }
  //   return value;
  // }
}
