import { Component, EventEmitter, Output } from '@angular/core';
import { MaintenanceService } from '../../services/maintenance.service';
import { MaintenanceModeEnum } from '../../../shared/enums/maintenance-mode.enum';

@Component({
    selector: 'app-maintenance',
    templateUrl: 'maintenance.component.html',
    styleUrls: ['maintenance.component.scss']
})

export class MaintenanceComponent {
  constructor(private _maintenanceService: MaintenanceService) {}
  @Output() refreshMaintenanceMode = new EventEmitter<boolean>();

  public refreshMaintenance() {
    this._maintenanceService.getMaintenanceMode().subscribe(maintenanceMode => {
      switch (maintenanceMode) {
        case MaintenanceModeEnum.None:
        case MaintenanceModeEnum.Login:
          this.refreshMaintenanceMode.emit(true);
          return;

        case MaintenanceModeEnum.Register:
          this.refreshMaintenanceMode.emit(
            window.location.pathname !== '/register' && window.location.pathname !== '/register-panel'
          );
          return;

        case MaintenanceModeEnum.Global:
          this.refreshMaintenanceMode.emit(window.location.pathname.includes('/login'));
          return;
      }
    })
  }
}
