import { Component } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { TwoFAService } from '../../../two-f-a/services/two-f-a.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AppConsts } from '../../../shared/_constant/AppConsts';

@Component({
  selector: 'deep-link-two-fa',
  templateUrl: 'deep-link-two-fa.html',
})
export class DeepLinkTwoFa {

  deepUrliOS: string = "apiarybuzz://verified2fa";
  deepUrlAndroid: string = "apiarybuzz://verified2fa";

  constructor(
    private _twoFAService: TwoFAService,
    private _toastrService: ToastrService,
    private _activatedRoute: ActivatedRoute,
    ) {
    this.verifyCode(this._activatedRoute.snapshot.paramMap.get('id'));
  }

  private verifyCode(code: string) {
    this._twoFAService.verify2FACode(code)
      .pipe(catchError((err) => {
        this._toastrService.error(err.error.message);
        return of(null);
      })).subscribe(() => this.checkPlatform());
  }

  private checkPlatform(){
    var userAgentString = navigator.userAgent.toLowerCase();
    if (userAgentString.indexOf("iphone") > -1 || userAgentString.indexOf("ipod") > -1 || userAgentString.indexOf("ipad") > -1) {
      setTimeout(function () {
        window.location.href = AppConsts.urlAppStore;
      }, 3000);
      window.location.href = this.deepUrliOS;
    } else if (/android/.test(userAgentString)) {
      setTimeout(function () {
        window.location.href = AppConsts.urlGooglePlay;
      }, 3000);
      window.location.href = this.deepUrlAndroid;
    } else {
      window.location.href = AppConsts.webAppUrl + '/two-fa';
    }
  }
}
