import { Component, OnInit, Input } from '@angular/core';

import { Survey } from '../../models/survey.model';

@Component({
  selector: 'survey-item',
  templateUrl: 'survey-item.component.html',
  styleUrls: ['survey-item.component.scss']
})

export class SurveyItemComponent implements OnInit {

  @Input() survey: Survey;

  constructor() {}

  ngOnInit() {
  }

  returnURLImage() {
    return "url(" + this.survey.image + ")";
  }
}
