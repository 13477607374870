import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
        
    handle(params: MissingTranslationHandlerParams) {  
        let response = params.key;                        
        let language = JSON.parse(localStorage.getItem('en'));             
        language && (response = language[params.key]);  
        if(response)      
            return response;
        else 
            return params.key
    }
}