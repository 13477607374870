import { TwoFAStatusEnum } from '../enums/two-f-a.enum';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class TwoFAVerifyPasswordRequest {
  email: string;
  password: string;
  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class TwoFAAccountInfo {
  isAuthenticated: boolean;
  authenticationStatus: TwoFAStatusEnum;
  email: string;
  constructor(obj: any) {
    this.isAuthenticated = !!obj['2fa_authen'];
    this.authenticationStatus = obj['status_2fa'];
    this.email = obj['email'];
  }
}

export class TwoFALoginPayload {
  email: string;
  password: string;
  device_info: string;
  totp_code: string;

  constructor(obj: any) {
    this.email = obj['email'];
    this.password = obj['password'];
    this.device_info = obj['device_info'];
    this.totp_code = obj['totp_code'];
  }
}

export class TwoFACodeResponse {
  qrCode: SafeUrl;
  secretKey: string;
  constructor(obj: any, domSanitizer: DomSanitizer) {
    if (!obj) {
      return;
    }
    this.qrCode = domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${obj['qr_code']}`);
    this.secretKey = obj['secret_key'];
  }
}

export class TwoFAChangeStatusRequest {
  status_2fa: TwoFAStatusEnum;
  totp_code: string;
  secret_key: string;
  constructor(status: TwoFAStatusEnum, code: string, secretKey?: string) {
    this.status_2fa = status;
    this.totp_code = code;
    this.secret_key = secretKey;
  }
}
