import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MaintenanceModeEnum } from '../../shared/enums/maintenance-mode.enum';
import { Observable } from 'rxjs/Observable';
import { Utilities } from '../../helpers/utilities';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  public isMaintenanceModeOn$ = new BehaviorSubject<boolean>(false);
  constructor(private _u: Utilities) {}

  getMaintenanceMode(): Observable<MaintenanceModeEnum> {
    return this._u.req.get('/api/v1/maintenance-mode')
      .pipe(map(response => response.data.maintenance_mode));
  }
}
