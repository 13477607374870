import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-thank-you',
    templateUrl: 'thank-you.component.html',
    styleUrls: ['thank-you.component.scss']
})

export class ThankYouPageComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.getSaleInfo();
    }

    ngOnDestroy() {
        document.getElementById('st_code').innerHTML = '';
    }

    hideIF() {
        document.getElementById('IF').style.visibility = '';
    }

    getSaleInfo() {
        document.getElementById('st_code').style.visibility = 'hidden';
        document.getElementById('st_code').innerHTML = '<iframe src="https://apiarybuzz.ositracker.com/sales/salejs/amount:10/transaction:B" alt="" id=IF width=50 height=50 border="0" frameborder="0" onload="hideIF()">';
    }
}