import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { HomeService } from '../../../home/services/home.service';
import { PanelInfoStatusEnum } from '../../../shared/enums/panel-info-status.enum';

@Component({
  selector: 'redeem-detail-modal',
  templateUrl: 'redeem-detail-modal.html',
  styleUrls: ['redeem-detail-modal.scss']
})
export class RedeemDetailModalComponent implements OnInit {

  @Input() data: any;
  message: string;
  two_option_left: boolean = true;
  accountInfo: any;
  modifiedData = {};
  updatedInfo: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private _router: Router,
    private _homeService: HomeService
  ) {
  }

  ngOnInit() {
    this.chooseLeftAsDefault();
    this.modifiedData['redeem_id'] = this.data['id'];
    this.modifiedData['description'] = this.data['description'];
    this.modifiedData['sort_order'] = 1;
    this.modifiedData['qty'] = 1;
    this.modifiedData['redeem_info_type'] = this.data['type'];
    this.modifiedData['redeem_date'] = this.getCurrentDate();
    this.modifiedData['process_date'] = this.modifiedData['redeem_date'];
    this._homeService.getAccountInfo().subscribe(res => {
      this.accountInfo = res['data'];
      if (!this.data['show_paypal']) {
        this.updatedInfo = (this.accountInfo.bank_account_name && this.accountInfo.bank_account_number && this.accountInfo.bank_name && this.data.type == 'money') ? true : false;
      } else {
        this.updatedInfo = (!(this.accountInfo.bank_account_name && this.accountInfo.bank_account_number && this.accountInfo.bank_name) && !this.accountInfo.paypal_recipient && this.data.type == 'money') ? false : true;
      }
    });
  }

  updateAccountInfo() {
    this.activeModal.dismiss();
    this._router.navigateByUrl(`/profile-setting?tab=personal&redirect=redeem&id=${this.data.id}`);
  }

  chooseLeftAsDefault() {
    if (this.data['type'] === "voucher") {
      this.chooseVoucher('voucher');
    } else if (this.data['type'] === "money") {
      this.chooseOption('left');
    }
  }

  includeMessage(message) {
    this.data['new-message'] = message;
  }

  chooseVoucher(voucher) {
    this.two_option_left = true;
    this.modifiedData['redeem_info_type'] = voucher;
    this.modifiedData['redeem_date'] = this.getCurrentDate();
    this.modifiedData['process_date'] = this.modifiedData['redeem_date'];
  }

  chooseOption(option_side) {
    if (option_side === 'left') {
      this.two_option_left = true;
      this.modifiedData['redeem_info_type'] = "bank";
    } else if (option_side === 'right') {
      this.two_option_left = false;
      this.modifiedData['redeem_info_type'] = "paypal";
    }
    this.modifiedData['redeem_date'] = this.getCurrentDate();
    this.modifiedData['process_date'] = this.modifiedData['redeem_date'];
  }

  getCurrentDate() {
    let dateTime = new Date();
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    month = this.formatCharacterDate(month);
    let day = dateTime.getDate();
    day = this.formatCharacterDate(day);
    let hh = dateTime.getHours();
    hh = this.formatCharacterDate(hh);
    let mm = dateTime.getMinutes();
    mm = this.formatCharacterDate(mm);
    let ss = dateTime.getSeconds();
    ss = this.formatCharacterDate(ss);
    let formatDate = year + '-' + month + '-' + day + ' ' + hh + ':' + mm + ':' + ss;
    return formatDate;
  }

  formatCharacterDate(value) {
    if (typeof value !== "undefined") {
      value = value.toString();
      if (value.length === 1) {
        return '0' + value;
      } else if (value.length === 2) {
        return value;
      }
    }
    return value;
  }

  returnURLImage() {
    return "url(" + this.data.image + ")";
  }

  redirectToRedeemInfo(): void {
    this._router.navigate(["/profile-setting"], { queryParams: { tab: 'redeem' } });
    this.activeModal.dismiss();
  }

}
