import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SgtDateTimePipe } from './sgt-date-time.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    SgtDateTimePipe,
  ],
  declarations: [ SgtDateTimePipe ],
})
export class PipesModule { }
