import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecuredApiInterceptor } from './helpers/secured-api.interceptor';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular5-social-login";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { ShareButtonModule } from '@ngx-share/button';
import { ShareModule } from '@ngx-share/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MAT_DATE_LOCALE } from '@angular/material';

import { Utilities } from './helpers/utilities';
import { ConfigurationService, ConfigurationModule } from './shared/services/_services/configuration';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ScriptLoaderService } from './helpers/script-loaders.service';
import { SharedModule } from './shared/shared.module';
import { MyMissingTranslationHandler } from './helpers/custom-missing-translate';
import { HomeService } from './home/services/home.service';
import { CommonService } from './shared/services/common.service';
import { PingService } from './shared/services/ping.service';
import { NoInternetComponent } from './shared/components/no-internet/no-internet.component';
import { LanguagesBehaviorService } from './shared/services/languages.behavior.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { StorageService } from './shared/services/storage.service';
import { UserService } from './shared/services/user.service';
import { DownloadMobileAppsComponent } from './download-mobile-apps/download-mobile-apps.component';
import { DownloadMobileAppsModule } from './download-mobile-apps/download-mobile-apps.module';
import { StaticPagesModule } from './static-pages/static-pages.module';
import { TwoFACongratulationComponent } from './two-f-a/components/two-f-a-congratulation/two-f-a-congratulation.component';


// Configs
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("523303831452061")
      },
      // {
      //   id: GoogleLoginProvider.PROVIDER_ID,
      //   provider: new GoogleLoginProvider("652244181545-ll65cjt00096nasg46qan3qrphv549fa.apps.googleusercontent.com")
      // },
    ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NoInternetComponent,
    TwoFACongratulationComponent
  ],
  imports: [
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      useDefaultLang: false,
    }),
    SocialLoginModule,
    FontAwesomeModule,
    NgbModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    ShareButtonsModule.forRoot(),
    ShareButtonModule.forRoot(),
    ShareModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    DownloadMobileAppsModule,
    StaticPagesModule,
  ],
  providers: [
    Utilities,
    ScriptLoaderService,
    ConfigurationService,
    ConfigurationModule.init(),
    [
      { provide: HTTP_INTERCEPTORS, useClass: SecuredApiInterceptor, multi: true }
    ],
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'en-US'
    },
    HomeService,
    CommonService,
    PingService,
    LanguagesBehaviorService,
    StorageService,
    UserService
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    NoInternetComponent,
    DownloadMobileAppsComponent,
    TwoFACongratulationComponent
  ]
})
export class AppModule { }
