import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dynamicStrcut'})
export class DynamicStrCut implements PipeTransform {
transform(text: string, length: number): string {
  if (!text) {
    return '';
  }
    let sub = "";
    let len = 0;
    let minWord = 3;

        $.each(text.split(" "), function (index, value) {
            var part = "";
            if (sub != "") {
                part = " " + value;
            } else {
                part = value;
            }
            sub += part;
            len = len + part.length;
            if (value.length > minWord && sub.length >= length) {
                return false;
            }
        });
        if (len < text.length) {
            sub = sub + "...";
        }
        return sub;
    }
}
