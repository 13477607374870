import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'stringLength'})
export class StringLengthPipe implements PipeTransform {
  transform(text: string): string {
    if(text && text.length > 39){
        let shortenTest = text.substring(0, 40) + '...';
        return shortenTest;
    } else if(text && text.length <= 39) {
        return text;
    }
  }
}