import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'redeem-zoom-modal',
    templateUrl: 'redeem-zoom-modal.component.html',
    styleUrls: ['redeem-zoom-modal.component.scss']
})

export class RedeemZoomModalComponent implements OnInit {

    @Input() src;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() { }
}