﻿import { ISingPassConfig } from '../models';

export class AppConsts {
  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static clientId: string;
  static clientSecret: string;
  static appId: string;
  static singPassConfig: ISingPassConfig;
  static webAppUrl: string;
  static readonly urlAppStore: string =
    "https://itunes.apple.com/app/apriarybuzz/id1436259514?mt=8";
  static readonly urlGooglePlay: string =
    "https://play.google.com/store/apps/details?id=com.innobaygroup.apiarybuzz";
}
