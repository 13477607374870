import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/services/common.service';
import { AppConsts } from '../../../shared/_constant/AppConsts';

@Component({
    selector: 'deep-link-login',
    templateUrl: 'deep-link-login.html',
    styleUrls: ['deep-link-login.scss']
})

export class DeepLinkLoginComponent implements OnInit {

    deepUrliOS: string = "com.sts.survey://login";
    deepUrlAndroid: string = "apiarybuzz://login";
    deepUrlWeb: string = "https://apiarybuzz.com/login";

    constructor(private _commonService: CommonService) {         
    }

    ngOnInit() {
        this._commonService.getConfigLinks().subscribe(success => {
            this.deepUrlWeb = success['data']['domain_link']+ 'login';
            this.checkPlatform();            
        });
    }

    checkPlatform(){
        var userAgentString = navigator.userAgent.toLowerCase();
        if (userAgentString.indexOf("iphone") > -1 || userAgentString.indexOf("ipod") > -1 || userAgentString.indexOf("ipad") > -1) {            
            setTimeout(function () {
                window.location.href = AppConsts.urlAppStore;                
            }, 3000);
            window.location.href = this.deepUrliOS;
        } else if (/android/.test(userAgentString)) {            
            setTimeout(function () { 
                window.location.href = AppConsts.urlGooglePlay;
            }, 3000);
            window.location.href = this.deepUrlAndroid;
        } else if (userAgentString.indexOf("mac") > -1) {
            window.location.href = this.deepUrlWeb;
        } else {
            window.location.href = this.deepUrlWeb;
        }
    }
}