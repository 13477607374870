import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

import { HomeService } from '../../../home/services/home.service';
import { RedeemService } from '../../services/redeem.service';
import { RedeemZoomModalComponent } from '../redeem-zoom-modal/redeem-zoom-modal.component';
import { RedeemZoomEVoutecherModalComponent } from '../redeem-zoom-e-voutecher-modal/redeem-zoom-e-voutecher-modal.component';
import { PanelInfoStatusEnum } from '../../../shared/enums/panel-info-status.enum';

@Component({
  selector: 'redeemed-detail-modal',
  templateUrl: 'redeemed-detail-modal.html',
  styleUrls: ['redeemed-detail-modal.scss']
})
export class RedeemedDetailModalComponent implements OnInit {

  @Input() data: any;
  message: string;
  visibility: boolean = true;
  two_option_left: boolean = true;
  accountInfo: any;
  modifiedData = {};
  infoRedeemedDetail: any;
  qrCode: string;
  paramQrCode: string;

  panelInfoStatusEnum = PanelInfoStatusEnum;

  constructor(
    public activeModal: NgbActiveModal,
    private _homeService: HomeService,
    private _redeemService: RedeemService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.modifiedData['redeem_id'] = this.data['id'];
    this.modifiedData['redeem_history_id'] = this.data['redeem_history_id'];
    this.modifiedData['description'] = this.data['description'];
    this.modifiedData['sort_order'] = 1;
    this.modifiedData['qty'] = 1;

    forkJoin(
      this._homeService.getAccountInfo(),
      this._redeemService.getRedeemedDetail(this.data['redeem_history_id'])
    ).subscribe(success => {
      this.accountInfo = success[0]['data'];
      this.infoRedeemedDetail = success[1]['data'];
      this.qrCode = this.infoRedeemedDetail.redeem_code || "";
      this.paramQrCode = `data:image/png;base64,${this.qrCode}`;
    });

  }

  zoom(data) {
    this.visibility = false;
    const modalRef = this.modalService.open(RedeemZoomModalComponent, { windowClass: 'img-zoom', centered: true });
    modalRef.componentInstance.src = data;
    modalRef.result.then(result => {
      this.visibility = true;
    }, reason => {
      this.visibility = true;
    });
  }

  zoomQrCode(data) {
    this.visibility = false;
    const modalRef = this.modalService.open(RedeemZoomEVoutecherModalComponent, { windowClass: 'e-voucher-zoom', centered: true });
    modalRef.componentInstance.src = data;
    modalRef.result.then(result => {
      this.visibility = true;
    }, reason => {
      this.visibility = true;
    });
  }

  includeMessage(message) {
    this.data['new-message'] = message;
  }

  getCurrentDate() {
    let dateTime = new Date();
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    month = this.formatCharacterDate(month);
    let day = dateTime.getDate();
    day = this.formatCharacterDate(day);
    let hh = dateTime.getHours();
    hh = this.formatCharacterDate(hh);
    let mm = dateTime.getMinutes();
    mm = this.formatCharacterDate(mm);
    let ss = dateTime.getSeconds();
    ss = this.formatCharacterDate(ss);
    let formatDate = year + '-' + month + '-' + day + ' ' + hh + ':' + mm + ':' + ss;
    return formatDate;
  }

  formatCharacterDate(value) {
    if (typeof value !== "undefined") {
      value = value.toString();
      if (value.length === 1) {
        return '0' + value;
      } else if (value.length === 2) {
        return value;
      }
    }
    return value;
  }

}
