import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'static-pages',
    templateUrl: 'static-pages.component.html',
    styleUrls: ['static-pages.component.scss']
})

export class StaticPageComponent implements OnInit {
    constructor(
        private _translateService: TranslateService
    ) { }

    ngOnInit() { 
        this.translate();
    }

    translate() {
        let language = localStorage.getItem('selectedLanguage') || 'en';        
        this._translateService.use(language);
    }
}