import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { filter } from 'rxjs/operators';

import { Utilities } from "./helpers/utilities";

import { CommonService } from "./shared/services/common.service";
import { PingService } from "./shared/services/ping.service";
import { LanguageService } from './shared/services/languages.service';

import { NoInternetComponent } from "./shared/components/no-internet/no-internet.component";

import { PanelUrl } from "./panel-info/constants/panel-info";
import { AppConsts } from "./shared/_constant/AppConsts";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MaintenanceService } from './static-pages/services/maintenance.service';
import { GoogleApiService } from './shared/services/google-api.service';
import jwt_decode from 'jwt-decode';

declare var fbq;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit, OnInit {
  isMaintenanceOn$ = new BehaviorSubject<boolean>(false);
  profileUser: any;
  isShowModal: boolean = false;
  countPing: number = 0;
  template = `
  <div class="container-loading">
    <div class="panel">
        <img src="assets/img/gif/loadinganimation.gif" />
    </div>
  </div>`;

  constructor(
    private _translate: TranslateService,
    private _languageService: LanguageService,
    private _commonService: CommonService,
    private _pingService: PingService,
    private _modalService: NgbModal,
    private _u: Utilities,
    private _router: Router,
    private _maintenanceService: MaintenanceService,
    private _googleApiService: GoogleApiService
  ) {
    this.initLanguage();
  }

  ngOnInit() {
    this._checkMaintenance();
    localStorage.removeItem('initOneSignal');
    this.getLinksConfig();
    this.pingToServer();
    // checking the user is already signed
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      this._u.req.get(PanelUrl.getInfoAccount).subscribe(res => {
        document.cookie = "access_token=" + accessToken + ";path=/;domain=apiarybuzz.com";
        this.profileUser = res.data;
        // config one signal
        this.configOneSignal();
        localStorage.setItem('initOneSignal', 'true');
      });
    }
    this._trackingRouting();
    this._setupGoogleLogin();
  }

  ngAfterViewInit() {

  }

  public refreshMaintenance(refresh: boolean) {
    if (refresh) {
      if (window.location.pathname.includes('/login')) {
        this._maintenanceService.isMaintenanceModeOn$.next(false);
      } else {
        window.location.reload(true);
      }
    }
  }

  private _setupGoogleLogin() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: "652244181545-ll65cjt00096nasg46qan3qrphv549fa.apps.googleusercontent.com",
      callback: (response: any) => {
        if (!response.credential) {
          return;
        }
        const userData = jwt_decode(response.credential);
        this._googleApiService.googleLoginCredential$.next({
          ...userData,
          credential: response.credential
        });

        // @ts-ignore
        google.accounts.id.disableAutoSelect();
      },
      auto_select: false,
      cancel_on_tap_outside: true,
    });

    // @ts-ignore
    google.accounts.id.disableAutoSelect();
  }

  private _checkMaintenance() {
    this._maintenanceService.isMaintenanceModeOn$.asObservable()
      .subscribe(isMaintenanceModeOn => {
        this.isMaintenanceOn$.next(isMaintenanceModeOn);
      });
  }

  private initLanguage(): void {
    this._languageService.getAllLanguages().subscribe(res => {
      // pass data to dropdown country
      if (res.data.length > 0)
        this._languageService.listLanguagesSubject.next(res.data);
      // check previous selected language and set default if is not exist
      let selectedLanguage = localStorage.getItem('selectedLanguage');
      if (!selectedLanguage || selectedLanguage === "") {
        localStorage.setItem('selectedLanguage', 'en');
        selectedLanguage = "en";
      }
      // get default language
      this._languageService.getPackedLanguages('en').subscribe(res => {
        if (res.code === 503) {
          return;
        }
        this._translate.setTranslation('en', res.data.dict);
        localStorage.setItem('en', JSON.stringify(res.data.dict));
      }, err => {
        this._translate.setTranslation('en', {});
        localStorage.setItem('en', JSON.stringify({}));
      });
      // get packed language and set to system
      this._languageService.getPackedLanguages(selectedLanguage).subscribe(res => {
        if (res.code === 503) {
          return;
        }
        this._translate.setTranslation(selectedLanguage, res.data.dict);
        localStorage.setItem(selectedLanguage, JSON.stringify(res.data.dict));
        this._translate.use(selectedLanguage);
      }, err => {
        this._translate.setTranslation(selectedLanguage, {});
        localStorage.setItem(selectedLanguage, JSON.stringify({}));
        this._translate.use(selectedLanguage);
      });
    });
  }

  configOneSignal() {
    var appId = AppConsts.appId;
    var OneSignal = window['OneSignal'] || [];
    OneSignal.push(["init", {
      appId: appId,
      autoRegister: false,
      persistNotification: true,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false
      },
      welcomeNotification: {
        disable: true
      },
      promptOptions: {
        customlink: {
          enable: false
        }
      }
    }]);

    let that = this;
    OneSignal.push(function () {
      OneSignal.isPushNotificationsEnabled().then(function (isEnabled) {
        if (isEnabled) {
          OneSignal.getUserId(function (userId) {
            if (!userId) {
              return;
            }
            let model = {
              player_id: userId,
              type_device: 'web'
            };
            that._u.req.post(PanelUrl.postDevicePlayer, model).subscribe(res => {
              console.log(res);
            });
          });
        }
        else {
          OneSignal.push(function () {
            OneSignal.registerForPushNotifications();
          });
        }
      });
    });
  }

  private pingToServer() {
    this._pingService.pingStream.subscribe(res => {
      if (res) {
        this.countPing = 0;
        if (this.isShowModal) {
          window.location.reload(true);
        }
        this.isShowModal = false;
      } else {
        this.countPing++;
        if (this.countPing > 1 && !this.isShowModal) {
          this.isShowModal = true;
          this._modalService.open(NoInternetComponent, { centered: true });
        }
      }
    });
  }

  getLinksConfig() {
    this._commonService.getConfigLinks().subscribe(success => {
      localStorage.setItem('faq_link', success['data']['faq_link']);
      localStorage.setItem('term_and_condition_link', success['data']['term_and_condition_link']);
      localStorage.setItem('privacy_policy_link', success['data']['privacy_policy_link']);
    });
  }

  private _trackingRouting(): void {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      fbq('track', event.url);
    });
  }
}
