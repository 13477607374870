import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sgtDateTime'
})
export class SgtDateTimePipe implements PipeTransform {
  transform(value: Date): Date {
    if (!value || isNaN(new Date(value).getTime())) {
      return null;
    }

    return new Date(new Date(value).getTime() + 8 * 60 * 60 * 1000);
    // @ts-ignore
    // return new Date(new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Singapore' }));
  }

}
