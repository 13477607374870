import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { StaticPagesService } from '../../services/static-pages.service';
import { Common, Patterns } from '../../../shared/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../shared/services/common.service';

declare var $ :any;

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.component.html',
    styleUrls: ['forgot-password.component.scss']
})

export class ForgotPasswordComponent implements OnInit, AfterViewInit {

    isPrevented: boolean = false;
    submitting: boolean = false;
    isSendEmail: boolean = false;
    email: string;
    disableSecurityQuestion: boolean = false;
    emailPattern = Patterns.email;
    captcha: any;
    siteKey: string;
    forgotPasswordDeepLinkToLogIn = "https://apiarybuzz.com/back-to-login";

    @ViewChild('submitForm') submitForm: NgForm;

    constructor(
        private _toastr: ToastrService,
        private _staticPagesService: StaticPagesService,
        private _translate: TranslateService,
        private _loadingSpinnerService: Ng4LoadingSpinnerService,
        private _commonService: CommonService
    ) {
        this.siteKey = Common.keyCaptcha;
    }

    ngOnInit() {
        this._commonService.getConfigLinks().subscribe(success => {
            this.forgotPasswordDeepLinkToLogIn = success['data']['domain_link'] + 'back-to-login';
        });
    }

    ngAfterViewInit() {
        setTimeout(function() {
            $('ifame').css('min-height', '78px');
        }, 2000);
    }

    checkPlatform(){
        var userAgentString = navigator.userAgent.toLowerCase();
        if (userAgentString.indexOf("iphone") > -1 || userAgentString.indexOf("ipod") > -1 || userAgentString.indexOf("ipad") > -1) {
            return false;
        } else if (/android/.test(userAgentString)) {
            return false;
        } else if (userAgentString.indexOf("mac") > -1) {
           return true;
        } else {
            return true;
        }
    }

    onSubmit() {
        this.submitting = true;
        let data = {
            email: this.email.toString().toLocaleLowerCase(),
            captcha: this.captcha
        };
        if (this.submitForm.form.invalid) {
            this._translate.get('login_error_email_required').subscribe(res => {
                this._toastr.error(res);
            });
        }
        this._loadingSpinnerService.show();
        this._staticPagesService.forgotPassword(data).subscribe(res => {
            this.disableSecurityQuestion = res.code == 202;
            this.isSendEmail = true;
            this.submitting = true;
            this._loadingSpinnerService.hide();
        }, error => {
            if(error.status == 401 || error.code == 401) {
                this.isPrevented = true;
                this._toastr.error('Unable to connect to server, please try again later.');
            } else {
                const key = error.error['message'];
                this._translate.get(key).subscribe(res => {
                    this._toastr.error(res);
                });
            }
            this.submitting = false;
            this._loadingSpinnerService.hide();
        });
    }
}
