import { Injectable } from '@angular/core';
import { IUserInfo } from '../models/user-info.model';

@Injectable()
export class StorageService {

    PANEL_INFO = 'panel_info';
    USER_INFO = 'user_info';

    constructor(
    ) { }

    updateUserInfo(data: IUserInfo) {
        let info = Object.assign({}, JSON.parse(localStorage.getItem(this.USER_INFO)), data);
        localStorage.setItem(this.USER_INFO, JSON.stringify(info));
    }

    getUserInfo() {
        let data = localStorage.getItem(this.USER_INFO);
        return data ? JSON.parse(data) : data;
    }

    updatePanelInfo(data) {
        let panelInfo = Object.assign({}, JSON.parse(localStorage.getItem(this.PANEL_INFO)), data);
        localStorage.setItem(this.PANEL_INFO, JSON.stringify(panelInfo));
    }

    getPanelInfo() {
        let data = localStorage.getItem(this.PANEL_INFO);
        return data ? JSON.parse(data) : data;
    }

    removePanelInfo() {
        localStorage.removeItem(this.PANEL_INFO);
    }

    clear() {
        localStorage.removeItem(this.USER_INFO);
        localStorage.removeItem('access_token');
        localStorage.removeItem('password');
        localStorage.removeItem('social_id');
    }
}
