import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './authentication/authentication.module#AuthenticationModule' },
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: '', loadChildren: './static-pages/static-pages.module#StaticPagesModule' },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
