import { Injectable, APP_INITIALIZER } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { AppConsts } from '../../_constant/AppConsts';
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable()
export class ConfigurationService {

  private _config: Object
  private _env: string;

    constructor(private _http: Http) { }
    load() {
        return new Promise((resolve, reject) => {
            this._env = 'development';
            if(environment.development){
                this._env = 'development';
            } else if (environment.staging){
                this._env = "staging";
            } else if(environment.production){
                this._env = 'production';
            }
            this._http.get('./assets/configuration/' + this._env + '.json')
                .map(res => res.json())
                .subscribe((data) => {
                    this._config = data;
                    AppConsts.appBaseUrl = (<any>this._config).appBaseUrl;
                    AppConsts.remoteServiceBaseUrl = (<any>this._config).remoteServiceBaseUrl;
                    AppConsts.singPassConfig = this._config['singPassConfig'];
                    AppConsts.clientId = (<any>this._config).clientId;
                    AppConsts.clientSecret = (<any>this._config).clientSecret;
                    AppConsts.appId = (<any>this._config).appId;
                    AppConsts.webAppUrl = (<any>this._config).webAppUrl;
                    resolve(true);
                },
                (error: any) => {
                    return Observable.throw(error.json().error || 'Server error');
                });
        });
    }
    // Is app in the development mode?
    isDevmode() {
        return this._env === 'development';
    }
    // Gets API route based on the provided key
    getApi(key: string): string {
        return this._config["API_ENDPOINTS"][key];
    }
    // Gets a value of specified property in the configuration file
    get(key: any) {
        return this._config[key];
    }
}

export function ConfigFactory(config: ConfigurationService) {
  return () => config.load();
}

export function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: ConfigFactory,
    deps: [ConfigurationService],
    multi: true
  }
}

const ConfigurationModule = {
  init: init
}

export {ConfigurationModule};
