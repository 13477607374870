import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/observable/interval';
import { Http } from '@angular/http';

@Injectable()
export class PingService {

    pingStream: Subject<any> = new Subject<any>();
    ping: number = 0;
    url: string = "https://cors-test.appspot.com/test";

    constructor(private _http: Http) {        
        const interval = Observable.interval(5000);
        interval.subscribe((data) => {
            let timeStart: number = performance.now();
            // this._http.get(this.url)
            //     .subscribe((data) => {
            //         let timeEnd: number = performance.now();
            //         let ping: number = timeEnd - timeStart;
            //         this.ping = ping;                    
            //         this.pingStream.next(ping);
            //     }, (err) => {
            //         this.pingStream.next(null);
            //     });
        });
    }
}