import { NgModule } from '@angular/core';
import { AlertModalComponent } from './alert-modal.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [AlertModalComponent],
  entryComponents: [AlertModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    TranslateModule
  ],
  exports: []
})
export class AlertModule {}
