import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";

import { Utilities } from "../../helpers/utilities"
import { ApiUrl } from '../constants/constants';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class LanguageService {

    listLanguagesSubject: Subject<any>;
    currentData : Observable<any>; 

    constructor(private _u: Utilities) {    
        this.listLanguagesSubject = new BehaviorSubject<any>(null);
        this.currentData = this.listLanguagesSubject.asObservable();
    }

    getAllLanguages(): Observable<any> {
        return this._u.req.get(ApiUrl.languages);
    }

    getPackedLanguages(code: string): Observable<any> {
        return this._u.req.get(ApiUrl.getLanguageByKey + code);
    }
}