import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'surveyStringLength'})
export class SurveyStringLengthPipe implements PipeTransform {
  transform(text: string): string {
    if(text && text.length > 126){
        let shortenTest = text.substring(0, 127) + '...';
        return shortenTest;
    } else if(text && text.length <= 126) {
        return text;
    }
  }
}