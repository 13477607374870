import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Utilities } from '../../helpers/utilities';
import { StaticPageUrls } from '../constants/static-pages';

@Injectable()
export class StaticPagesService {

    constructor(private _u: Utilities) {
    }

    forgotPassword(data): Observable<any> {
        return this._u.req.post(StaticPageUrls.ForgotPassword, data, {observe : 'response'});
    }

    checkValidToken(token: any): Observable<any> {
        return this._u.req.post(StaticPageUrls.validToken, token);
    }

    setNewPassword(data): Observable<any> {
        return this._u.req.post(StaticPageUrls.resetPassword, data);
    }

}
