import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { ToastrService } from "ngx-toastr";

import { StaticPagesService } from "../../services/static-pages.service";

import { Patterns } from "../../../shared/constants/constants";
import { AccountType } from "../../../shared/enums/panel-info-status.enum";
import { VerificationMethod } from "../../constants/static-pages";

@Component({
  selector: "app-new-password",
  templateUrl: "new-password.component.html",
  styleUrls: ["new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit {
  VerificationMethod = VerificationMethod;
  PasswordPattern = Patterns.password;

  password: string;
  repassword: string;
  codeResetPassword = "";
  securityQuestion: string;
  securityAnswer: string;

  verificationMethod: string;

  isEnabledSecurityQuestion: boolean = false;
  isSubmitting: boolean = false;
  isResetPassword: boolean = false;
  isValidToken: boolean = true;

  private _securityQuestionAnswer: any;
  private _token: string;

  @ViewChild("submitForm") submitForm: NgForm;
  @ViewChild("passwordView") passwordValidate: NgModel;
  @ViewChild("rePasswordView") rePasswordValidate: NgModel;

  constructor(
    private _toastr: ToastrService,
    private _staticPagesService: StaticPagesService,
    private _translate: TranslateService
  ) {}

  ngOnInit() {
    const url = window.location.href;
    this._token = url.includes("=") && url.split("=")[1];
    this._token && this._getAccountInfo();
  }

  onSubmit(): void {
    this._isValidForm() && this._handleResetPassword();
  }

  private _isValidForm(): boolean {
    let keyErrorMessage;
    if (!this.password || this.password == "") {
      keyErrorMessage = "new_pass_is_required";
    } else if (!this.passwordValidate.valid) {
      keyErrorMessage = "epd_new_pass_wrong";
    } else if (!this.repassword || this.repassword == "") {
      keyErrorMessage = "re_enter_pass_is_required";
    } else if (!this.rePasswordValidate.valid) {
      keyErrorMessage = "epd_retype_pass_wrong";
    } else if (this.password != this.repassword) {
      keyErrorMessage = "registration_error_not_match_pwd";
    } else if (
      this.verificationMethod === VerificationMethod.BySecurityQuestion
    ) {
      if (!this.securityAnswer || this.securityAnswer == "") {
        keyErrorMessage = "security_answer_is_required";
      } else if (
        this.isEnabledSecurityQuestion &&
        !this._isValidSecurityQuestion()
      ) {
        keyErrorMessage = "forgot_pwd_answer_error";
      }
    } else if (
      [
        VerificationMethod.ByCode,
        VerificationMethod.ByPartialUserCode,
      ].includes(this.verificationMethod)
    ) {
      if (!this.codeResetPassword) {
        keyErrorMessage = "code_reset_pass_is_required";
      }
    }
    keyErrorMessage &&
      this._translate.get(keyErrorMessage).subscribe((message: string) => {
        this._toastr.error(message);
      });

    return !keyErrorMessage;
  }

  private _isValidSecurityQuestion(): boolean {
    return (this.securityAnswer || '').toLocaleLowerCase() == this._securityQuestionAnswer.answer.toString().toLocaleLowerCase();
  }

  private _getAccountInfo(): void {
    const request = {
      token: this._token,
      form: "forgot_password",
    };
    this._staticPagesService.checkValidToken(request).subscribe(
      ({ data }) => {
        this.isValidToken = data && data.is_valid;
        if (this.isValidToken) {
          // this.isShowCodePassword = data.by_reset_code ;

          this._checkVerificationMethod(
            data.completed_panel_info,
            data.by_reset_code
          );
          this._getSecurityQuestion(data.security_question);
        }
      },
      () => {
        this.isValidToken = false;
      }
    );
  }

  private _checkVerificationMethod(
    accountType: AccountType,
    isResetByCode: boolean
  ): void {
    if (accountType === AccountType.PARTIAL) {
      this.verificationMethod = VerificationMethod.ByPartialUserCode;
    } else {
      this.verificationMethod = isResetByCode
        ? VerificationMethod.ByCode
        : VerificationMethod.BySecurityQuestion;
    }
  }

  private _getSecurityQuestion(securityQuestion: any): void {
    if (securityQuestion) {
      let securityQuestionArray = JSON.parse(securityQuestion);
      let randomSecurityQuestion =
        securityQuestionArray[
          Math.floor(Math.random() * securityQuestionArray.length)
        ];
      if (randomSecurityQuestion["question_1"] != null) {
        this._securityQuestionAnswer = {
          question: randomSecurityQuestion["question_1"]["question"],
          answer: randomSecurityQuestion["question_1"]["answer"],
        };
        this.securityQuestion = this._securityQuestionAnswer.question;
      }
      if (randomSecurityQuestion["question_2"] != null) {
        this._securityQuestionAnswer = {
          question: randomSecurityQuestion["question_2"]["question"],
          answer: randomSecurityQuestion["question_2"]["answer"],
        };
        this.securityQuestion = this._securityQuestionAnswer.question;
      }
      if (randomSecurityQuestion["question_3"] != null) {
        this._securityQuestionAnswer = {
          question: randomSecurityQuestion["question_3"]["question"],
          answer: randomSecurityQuestion["question_3"]["answer"],
        };
        this.securityQuestion = this._securityQuestionAnswer.question;
      }
      this.isEnabledSecurityQuestion = true;
    }
  }

  private _handleResetPassword(): void {
    const request = {
      token: this._token,
      password: this.password,
      reset_code: this.codeResetPassword,
    };

    this.isSubmitting = true;
    this._staticPagesService.setNewPassword(request).subscribe(
      () => {
        this.isSubmitting = this.isResetPassword = true;
        this._translate
          .get("new_password_success")
          .subscribe((message: string) => {
            this._toastr.success(message);
          });
      },
      (err: any) => {
        this.isSubmitting = false;
        this._translate.get(err.error.message).subscribe((message: string) => {
          this._toastr.error(message);
        });
      }
    );
  }
}
