import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { MaintenanceService } from '../static-pages/services/maintenance.service';

@Injectable()
export class SecuredApiInterceptor implements HttpInterceptor {

    jwtHelper;

    constructor(private _router: Router, private _maintenanceService: MaintenanceService) {
        this.jwtHelper = new JwtHelperService();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentlang = localStorage.getItem('selectedLanguage');
        if(!currentlang ){
            currentlang = 'en';
        }
        let token = localStorage.getItem('access_token');
        let methodChecker = req.method;
        let urlChecker = req.url;
        let headers = req.headers;
        // cancel call api from assets
        if(urlChecker.includes("assets"))
            return Observable.empty();
        if(methodChecker === "POST" && urlChecker.includes("api/v1/profile-basic")){
            headers = headers.set('lang', currentlang).set('Authorization', 'Bearer '+ token);
        } else {
            headers = headers.set('Content-Type', 'application/json').set('lang', currentlang).set('Authorization', 'Bearer '+ token);
        }
        req = req.clone({
            headers: headers
        });
        return next.handle(req).do((event: any) => {
          if ((event && event.code === 503) || (event && event.body && event.body.code === 503)) {
            this._maintenanceService.isMaintenanceModeOn$.next(true);
          }
        }).catch(err => {
            if(err.error.code === 601 && !window.location.href.includes("new-password") && !window.location.href.includes("register") && !window.location.href.includes("forgot-password") && !window.location.href.includes("redirect")){
                localStorage.removeItem('access_token');
                document.cookie = "access_token=;path=/;domain=saigontechnology.vn";
                if(!window.location.href.includes("verify-email"))
                    this._router.navigate(['login']);
            }
            return Observable.throw(err);
        });
    }
}
