import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { IUserInfo } from '../models/user-info.model';
import { PanelInfoStatusEnum } from '../enums/panel-info-status.enum';

@Injectable()
export class UserService {

    constructor(private _storage: StorageService) { }

    isIncompleteMember() {
        const userInfo: IUserInfo = this._storage.getUserInfo();
        return userInfo && (userInfo.memberStatus.toString() === PanelInfoStatusEnum.INCOMPLETE ||
            (userInfo.memberStatus.toString() === PanelInfoStatusEnum.PARTIAL && userInfo.isPartialPanel))
    }

}