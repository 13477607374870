import { NgModule } from "@angular/core";
import { PasswordRoutingModule } from "./static-pages-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { StaticPagesService } from "./services/static-pages.service";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";

import { StaticPageComponent } from "./static-pages.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { NewPasswordComponent } from "./components/new-password/new-password.component";
import { TermsAndConditionsComponent } from "./components/terms-and-conditions/terms-and-conditions.component";
import { TermsConditionsLoginComponent } from "./components/terms-conditions-login/terms-conditions-login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { RedirectPageComponent } from "./components/deep-link-redirect-page/deep-link-redirect-page";
import { FAQComponent } from "./components/faq/faq.component";
import { DeepLinkInviteComponent } from "./components/deep-link-invite/deep-link-invite";
import { DeepLinkLoginComponent } from "./components/deep-link-login/deep-link-login";
import { PanelInfoService } from "../panel-info/services/panel-info.service";
import { ThankYouPageComponent } from "./components/thank-you/thank-you.component";
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { DeepLinkTwoFa } from './components/deep-link-two-fa/deep-link-two-fa';

@NgModule({
  imports: [
    TranslateModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PasswordRoutingModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
  ],
  exports: [
    MaintenanceComponent,
  ],
  declarations: [
    StaticPageComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    TermsAndConditionsComponent,
    TermsConditionsLoginComponent,
    NotFoundComponent,
    RedirectPageComponent,
    FAQComponent,
    DeepLinkInviteComponent,
    DeepLinkLoginComponent,
    DeepLinkTwoFa,
    ThankYouPageComponent,
    MaintenanceComponent,
  ],
  providers: [StaticPagesService, PanelInfoService],
})
export class StaticPagesModule {}
