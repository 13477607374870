import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { Language } from '../../models/shared.model';
import { LanguageService } from '../../services/languages.service';

@Component({
    selector: 'select-country',
    templateUrl: 'select-country.component.html',
    styleUrls: ['select-country.component.scss']
})

export class SelectCountryComponent implements OnInit, AfterViewInit {
    
    @Output() select = new EventEmitter();

    data: Language[];
    selected: Language = {
        name: "English",
        code: "en"
    };
    isShow: boolean = false;
    maxWidth: number = 0;

    constructor(
        private _languageService: LanguageService,
        private el: ElementRef
    ) { }

    ngOnInit() {         
        this._languageService.currentData.subscribe(data => { 
            this.data = data;                                    
            if(data != null) {                         
                let selectedLanguage = localStorage.getItem('selectedLanguage');                
                data.forEach(element => {                   
                    let width = element.name.length * 10;
                    this.maxWidth = width > this.maxWidth ? width: this.maxWidth;
                    if(element.code === selectedLanguage)
                        this.selected = element;
                });
                this.maxWidth += 30;                
            }
        });       
    }

    ngAfterViewInit() {
    }

    click(): void {
        this.isShow = !this.isShow;
    }

    selectItem(index: any): void {
        this.isShow = false;
        this.selected = this.data[index];
        this.select.emit(this.selected);
    }

    @HostListener('document:click', ['$event'])
    handleClick(event: Event) {            
        if (!this.el.nativeElement.contains(event.target)) {
                this.isShow = false;          
        }
    }   
}