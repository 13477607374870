import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-redeem-zoom-e-voutecher-modal',
  templateUrl: './redeem-zoom-e-voutecher-modal.component.html',
  styleUrls: ['./redeem-zoom-e-voutecher-modal.component.scss']
})
export class RedeemZoomEVoutecherModalComponent implements OnInit {

  @Input() src;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() { }

}
