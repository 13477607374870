export const PanelInfoStatusEnum = {
    INCOMPLETE: '0',
    COMPLETED: '1',
    PARTIAL: '2'
};

export enum AccountType {
    INCOMPLETE,
    COMPLETED,
    PARTIAL,
};
