export class ApiUrl {
    public static readonly allLanguages: string = "/api/v1/language-packages";
    public static readonly languages: string = "/api/v1/languages";
    public static readonly getLanguageByKey: string = "/api/v1/languag-package/";
    public static readonly configuration: string = "/api/v1/configuration";
}

export class ListLanguage {
    public static listLanguage: any[] = [];
}

export class Common {
    public static readonly keyCaptcha = "6LetOnAUAAAAAPo72gSp-jKiazwGHzY1ipXup1bP";
    public static readonly copyright = "Copyright ©" + new Date().getFullYear() + " Innoprobe Insight Pte Ltd.";
}

export class Patterns {
    public static readonly password = /(?=.*\d)(?=.*[!@#\$%\^&\*\+\-\/\?\:\.\(\)\{\}\[\]\~\`\=\;\"\<\>\|\'\_\!\#\%\$\&\,\@\\])(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    public static readonly email = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
    public static readonly minEightCharacters = /.{8,}/;
}

export class Moment {
    public static readonly formatDate = 'YYYY-MM-DD';
    public static readonly formatDisplayDate = 'DD/MM/YYYY';
    public static readonly formatDatetime = 'YYYY-MM-DD HH:mm:ss';
}

export const MY_FORMATS = {
  parse: {
    dateInput: Moment.formatDisplayDate,
  },
  display: {
    dateInput: Moment.formatDisplayDate,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
