import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core'

@Directive({
    selector: '[beforeunload]',
})
export class BeforeUnloadDirective {
    @Output() unloadEvent = new EventEmitter();

    constructor(elementRef: ElementRef) {
    }

    // call this event handler before browser refresh
    @HostListener("window:beforeunload", ["$event"])
    unloadHandler(event: Event) {
        this.unloadEvent.emit();
    }
}