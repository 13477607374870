import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StaticPageComponent } from './static-pages.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { TermsConditionsLoginComponent } from './components/terms-conditions-login/terms-conditions-login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RedirectPageComponent } from './components/deep-link-redirect-page/deep-link-redirect-page';
import { FAQComponent } from './components/faq/faq.component';
import { DeepLinkInviteComponent } from './components/deep-link-invite/deep-link-invite';
import { DeepLinkLoginComponent } from './components/deep-link-login/deep-link-login';
import { ThankYouPageComponent } from './components/thank-you/thank-you.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { DeepLinkTwoFa } from './components/deep-link-two-fa/deep-link-two-fa';

const routes: Routes = [
  {
    path: '',
    component: StaticPageComponent,
    children: [
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'new-password', component: NewPasswordComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'terms-conditions', component: TermsConditionsLoginComponent },
      { path: 'not-found', component: NotFoundComponent },
      { path: 'help-faq', component: FAQComponent },
      { path: 'redirect', component: RedirectPageComponent },
      { path: 'invite', component: DeepLinkInviteComponent },
      { path: 'back-to-login', component: DeepLinkLoginComponent },
      { path: 'thank-you', component: ThankYouPageComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'verification-2fa/:id', component: DeepLinkTwoFa },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PasswordRoutingModule { }
