import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-download-mobile-apps',
  templateUrl: './download-mobile-apps.component.html',
  styleUrls: ['./download-mobile-apps.component.scss']
})
export class DownloadMobileAppsComponent {
  constructor(private _activeModal: NgbActiveModal) { }
  public closeModal() {
    this._activeModal.close();
  }
}
