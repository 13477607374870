export class Survey {
  id: number;
  title: string;
  description: string;
  point: number;
  estimate_duration: string;
  survey_server: string;
  total_question: number;
  image: string;
  reward_description: string;

  constructor(data?: any) {
    if (data) {
      this.id = data["id"] ? data["id"] : null;
      this.title = data["title"] ? data["title"] : null;
      this.description = data["description"] ? data["description"] : null;
      this.point = data["point"] ? data["point"] : null;
      this.estimate_duration = data["estimate_duration"] ? data["estimate_duration"] : null;
      this.survey_server = data["survey_server"] ? data["survey_server"] : null;
      this.total_question = data["total_question"] ? data["total_question"] : null;
      this.image = data["image"] ? data["image"] : null;
    }
  }
}
