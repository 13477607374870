import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { LanguageService } from './services/languages.service';
import { AuthGuard } from './services/auth-guard.service';
import { StringLengthPipe } from './pipes/string-length-pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SurveyDropdownComponent } from '../shared/components/survey-dropdown/survey-dropdown.component';


import { RedeemItemComponent } from '../redeem/components/redeem-item/redeem-item.component';
import { RedeemDetailModalComponent } from '../redeem/components/redeem-detail-modal/redeem-detail-modal';
import { RedeemedDetailModalComponent } from '../redeem/components/redeemed-detail-modal/redeemed-detail-modal';

import { LanguagesBehaviorService } from './services/languages.behavior.service';

import { SurveyStringLengthPipe } from './pipes/survey-string-length-pipe';
import { DynamicStrCut } from './pipes/dynamic-strcut-pipe';
import { BeforeUnloadDirective } from './directives/beforeunload.directive';

import {
  RelevantFormComponent,
  SurveyItemComponent,
  SearchDropdownComponent,
  ConfirmModalComponent,
  BaseComponent,
  CSOFormComponent
} from './components';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TranslateModule,
    ClickOutsideModule,
    ModalModule.forRoot(),
    PipesModule,
  ],
  declarations: [
    SurveyItemComponent,
    RedeemItemComponent,
    SurveyDropdownComponent,
    SearchDropdownComponent,
    StringLengthPipe,
    SurveyStringLengthPipe,
    RedeemDetailModalComponent,
    RedeemedDetailModalComponent,
    ConfirmModalComponent,
    DynamicStrCut,
    BaseComponent,
    RelevantFormComponent,
    CSOFormComponent,
    BeforeUnloadDirective
  ],
  exports: [
    NgbModule,
    DynamicStrCut,
    SurveyItemComponent,
    RedeemItemComponent,
    SurveyDropdownComponent,
    SearchDropdownComponent,
    ConfirmModalComponent,
    ClickOutsideModule,
    BaseComponent,
    RelevantFormComponent,
    BeforeUnloadDirective,
    CSOFormComponent
  ],
  providers: [
    LanguageService,
    AuthGuard,
    LanguagesBehaviorService,
  ],
  entryComponents: [
    RedeemDetailModalComponent,
    RedeemedDetailModalComponent,
    ConfirmModalComponent
  ]
})
export class SharedModule { }
