import { Component, OnInit, } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'base-component',
  template: '',
})
export class BaseComponent implements OnInit {

  public frm: FormGroup;
  public formErrors: { [key: string]: string };
  public validationMessages: { [key: string]: { [key: string]: string } };
  public controlConfig: { [key: string]: FormControl | FormArray };
  formValidated = false;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
  }

  public ngOnInit() {
    this.buildForm();
  }

  public buildForm() {
    this.frm = new FormGroup(this.controlConfig);
    this.frm.valueChanges.subscribe(data => this.onValueChanged(this.frm.getRawValue()));
    this.onValueChanged(); // (re)set validation messages now
  }

  public revalidateOnChanges(control): void {
    if (control && control._parent && !control._revalidateOnChanges) {
      control._revalidateOnChanges = true;
      control._parent
        .valueChanges
        .distinctUntilChanged((a, b) => {
          // These will always be plain objects coming from the form, do a simple comparison
          if (a && !b || !a && b) {
            return false;
          } else if (a && b && Object.keys(a).length !== Object.keys(b).length) {
            return false;
          } else if (a && b) {
            for (let i in a) {
              if (a[i] !== b[i]) {
                return false;
              }
            }
          }
          return true;
        })
        .subscribe(() => {
          control.updateValueAndValidity();
        });

      control.updateValueAndValidity();
    }
    return;
  }

  public conditional(conditional, validator) {
    return (control) => {
      this.revalidateOnChanges(control);

      if (control && control._parent) {
        if (conditional(control._parent)) {
          return validator(control);
        }
      }
    };
  }

  submitForm() {
    this.formValidated = true;

    if (this.frm.invalid) {
      this.onValueChanged(this.frm.value, true);

      for (let key in this.formErrors) {
        if (this.formErrors.hasOwnProperty(key) && this.formErrors[key]) {
          this.translate.get(this.formErrors[key]).subscribe(res => {
            this.toastr.error(res);
          });

          this.scrollToError();
          return;
        }
      }
    }
  }

  public onValueChanged(data?: any, submitedForm?: boolean) {
    let self = this;

    if (!self.frm) {
      return;
    }
    const form = self.frm;
    for (const field in self.formErrors) {
      if (self.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        self.formErrors[field] = '';
        const control = form.get(field);

        if (submitedForm && control.invalid) {
          control.markAsDirty();
        }
        if (control && control.dirty && !control.valid) {
          const messages = self.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              self.formErrors[field] += messages[key];
              break;
            }
          }

          if (control instanceof FormArray) {
            const controls = (control as FormArray).controls;
            for (let i in controls) {
              const ct = controls[i];

              if (submitedForm && ct.invalid) {
                ct.markAsDirty();
              }

              if (ct.invalid && ct.dirty) {
                for (const key in (ct as FormGroup).controls) {
                  const childCtl = (ct as FormGroup).controls[key];
                  if (childCtl.invalid) {
                    for (const err in childCtl.errors) {
                      let message: any = messages[err];
                      if (childCtl.errors.hasOwnProperty(err)) {
                        if (message instanceof Function) {
                          self.formErrors[field] = message(parseInt(i), key); // Default required validator
                        } else {
                          self.formErrors[field] = message; // Default required validator
                        }
                        break;
                      }
                    }
                    break;
                  }
                }
                break;
              }
            }
          }
        }
      }
    }
  }

  scrollTo(el: any): void {
    const y = el.getBoundingClientRect().top + window.scrollY - 120;
    window.scroll({
      top: y,
      behavior: 'auto'
    });

    setTimeout(() => {
      el.focus();
    });
  }

  scrollToError(querySelector: string = '.was-validated .ng-invalid'): void {
    setTimeout(() => {
      const firstElementWithError = document.querySelector(querySelector);
      firstElementWithError && this.scrollTo(firstElementWithError);
    });
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }
}
