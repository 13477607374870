import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'no-internet',
    templateUrl: 'no-internet.component.html',
    styleUrls: ['no-internet.component.scss']
})

export class NoInternetComponent implements OnInit {
    
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() { }

    reload() {
        window.location.reload(true);
    }
}