import { Component, OnInit, Input, Output, ElementRef, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';

@Component({
  selector: 'app-survey-dropdown',
  templateUrl: 'survey-dropdown.component.html',
  styleUrls: ['survey-dropdown.component.scss']
})
export class SurveyDropdownComponent implements OnInit, OnChanges {
  @ViewChild('searchBox') searchBox: SearchDropdownComponent;

  @Input() alwaysShow = false;
  @Input() placeholderSingle = 'Select Item';
  @Input() placeholderMultiple = 'Select Items';
  @Input() placeholderText = 'common_dropdown_type';
  @Input() icon = 'la la-list-alt';
  @Input() multiple = false;
  @Input() selectedItem: any;
  @Input() selected: any[] = [];
  @Input() data: any[];
  @Input() isSort = false;

  @Output() selectedData = new EventEmitter<any>();
  @Output() closeDropdown = new EventEmitter();

  @ViewChild('content') content: ElementRef;

  isShow = false;
  listBidding = [];
  filteredItems: any[] = [];
  selectedItemValue: string;
  search = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.multiple) {
      if (this.selected && this.selected.length === 0 && this.data && this.data.length > 0) {
        this.search = '';
        this.selected = [];
        this.data.forEach(item => (item.selected = false));
      }
    } else if (this.selectedItem) {
      this.selectedItemValue = this.selectedItem.value;
    }

    if (changes.data && this.alwaysShow) {
      this._filter();
    }
    if (this.selectedItem && this.selectedItem.id && this.data && this.data.length > 0) {
      const index = this.data.findIndex(d => d.id === this.selectedItem.id);
      this.data[index].selected = true;
      this.selectedItem = _.cloneDeep(this.data[index]);
    }
    this.selected = _.cloneDeep(this.selected);
    // if (this.selected && this.selected.length !== 0 && this.selected && this.data && this.data.length > 0) {
    //   this.selected.forEach(item => {
    //     this.data.forEach(dt => {
    //       if (dt.value === item.value) {
    //         dt.selected = true;
    //       }
    //     });
    //   });
    // }
  }

  ngOnInit() {
    this._filter();
  }

  removeSelected(item: any) {
    let position = this.selected.findIndex(s => s.id === item.id);
    this.selected.splice(position, 1);
    position = _.findIndex(this.data, datum => {
      return datum.id === item.id;
    });
    this.data[position].selected = false;
    this._filter();
    this.selectedData.emit(this.selected);
  }

  selectItem(item: any) {
    if (item.selected) {
      this.removeSelected(item);
    } else {
      if (!this.selected.includes(item)) {
        item.selected = true;
        this.selected.push(item);
        this.selectedData.emit(this.selected);
      }
    }
  }

  selectOneItem(itemSelected) {
    const index = this.data.findIndex(item => item.id === itemSelected.id);
    if (itemSelected && itemSelected.selected === true) {
      this.data.map(i => (i.selected = false));
      this.selectedItem = null;
      this.selectedItemValue = null;
    } else {
      this.data.map(i => (i.selected = false));
      this.selectedItem = this.data[index];
      this.data[index].selected = true;
      this.selectedItemValue = this.selectedItem.value;
    }
    this.isShow = false;
    this.selectedData.emit(this.selectedItem);
  }

  clearAll() {
    if (this.searchBox) {
      this.searchBox.setFocus();
    }
    this.resetData();
  }

  onClickedOutside(value) {
    if (value) {
      this.isShow = false;
    }
  }

  receiveDataFilterList(data) {
    this.search = data;
    this._filter();
  }

  searchData(searchData) {
    this.search = searchData;
    this.filteredItems = this.data ? [...this.data] : null;
    this.filteredItems = this.filteredItems.filter(d => d.value.toLowerCase().includes(searchData));
  }

  resetData() {
    if (this.multiple) {
      this.search = '';
      this.selected = [];
      this.selectedItem = null;
      this.filteredItems.forEach(item => {
        item.selected = false;
      });
      this.data.forEach(item => (item.selected = false));
      this._filter();
      this.selectedData.emit(this.selected);
    } else {
      this.selectedItem = null;
      this.selected = [];
      this.search = '';
      this.data.forEach(item => (item.selected = false));
      this._filter();
      this.selectedData.emit(this.selectedItem);
    }
  }

  private _filter() {
    if (this.data && this.data.length > 0) {
      this.listBidding = [...this.data];
      const sortData = _.orderBy(this.data, [data => data.value ? data.value.toString().toLowerCase() : null], ['asc']);
      if (this.isSort) {
        this.filteredItems = Object.assign([], sortData.filter(item => item.value ? item.value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 : false));
      } else {
        this.filteredItems = Object.assign([], this.data.filter(item => item.value ? item.value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 : false));
      }
      this.alwaysShow = true;
    } else {
      return;
    }
  }

}
