import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit, AfterViewInit {
  @Input() placeholderText = 'common_dropdown_type';
  @Input() search;

  @Output() searchData = new EventEmitter<any>();

  @ViewChild('searchText') searchText: ElementRef;

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.searchText.nativeElement.focus();
  }

  filter() {
    this.searchData.emit(this.search);
  }

  setFocus() {
    this.searchText.nativeElement.focus();
  }
}
