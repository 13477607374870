import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { map, tap } from 'rxjs/operators';
import { Utilities } from "../../helpers/utilities";
import { HomeUrl } from "../constants/home";

@Injectable({ providedIn: "root" })
export class HomeService {
  email: any;
  balance_points: number;

  private _accountInfo: any;

  constructor(private _u: Utilities) {}

  get accountInfo(): any {
    return this._accountInfo;
  }

  get isPrivatePolicyAccount(): boolean {
    return this._accountInfo && this._accountInfo["privacy_policy"];
  }

  get keyPrivacyPolicy(): any {
    return this._accountInfo["key_privacy_policy"];
  }

  acceptPolicy(): Observable<any> {
    return this._u.req.post(HomeUrl.acceptPolicy, null).pipe(map((response) => {
        if (response.code === 200) {
            this._accountInfo['privacy_policy'] = true;
        }
        return response;
    }));
  }

  getAccountInfo(): Observable<any> {
    return this._u.req.get(HomeUrl.accountInfo).pipe(
      tap((response) => {
        if (response && response.data) {
          this._accountInfo = response.data;
        }
      })
    );
  }

  logout(): Observable<any> {
    return this._u.req.get(HomeUrl.logout);
  }

  setEmail(email) {
    this.email = email;
  }

  getEmail() {
    return this.email;
  }

  setBalancePoints(balance_points) {
    this.balance_points = balance_points;
  }

  getBalancePoints() {
    return this.balance_points;
  }

  getNotification(): Observable<any> {
    return this._u.req.get(HomeUrl.notification);
  }

  sendDevicePlayer(data: any): Observable<any> {
    return this._u.req.post(HomeUrl.devicePlayer, data);
  }
}
