import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  firstParam = '/login'

  constructor(private _userService: UserService, private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    localStorage.setItem('firstParam', decodeURIComponent(state.url))
    let token = localStorage.getItem('access_token');
    if (!token) {
      window.location.href = "/login";
      return false;
    }

    if (decodeURIComponent(state.url) === '/panel-info' && !this._userService.isIncompleteMember()) {
      window.location.href = "/dashboard";
    }
    return true;
  }
}
