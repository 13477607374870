export class Panel {
    static step: any[] = [
        { step: 1, link: "/panel-info/personal" },
        { step: 2, link: "/panel-info/household" },
        { step: 3, link: "/panel-info/redeem" },
        { step: 4, link: "/panel-info/question" }
    ];
}

export class PanelUrl {
    public static readonly postDevicePlayer: string = "/api/v1/post-device-player";
    public static readonly getInfoAccount: string = "/api/v1/my-account";
    public static readonly screeningQuestions: string = "/api/v1/screening-question";
    public static readonly panelInfo: string = "/api/v1/personal-information";
    public static readonly langPackageKeyList: string = "/api/v1/lang_package_key_list";
    public static readonly options: string = "/api/v1/options/";
    public static readonly getDefaultProfile: string = "/api/v1/default-profile";
    public static readonly customConsent: string = '/api/v1/custom-consent';
    public static readonly personalSecurityQuestion: string  ='/api/v1/personal-security-question';
}
