import {Component, OnInit, AfterViewInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AppConsts} from '../../../shared/_constant/AppConsts';

@Component({
    selector: 'deep-link-invite',
    templateUrl: 'deep-link-invite.html',
    styleUrls: ['deep-link-invite.scss']
})

export class DeepLinkInviteComponent implements OnInit, AfterViewInit {

    public message = "Share Invitation";
    isWebView : boolean;
    deepUrliOS: string = "com.sts.survey://dashboard";
    deepUrlAndroid: string = "apiarybuzz://dashboard";
    deepUrlWeb: string = "https://apiarybuzz.com";

    constructor() {
    }

    ngOnInit() {
        this.chooseWebEnvironment();
        this.isWebView = navigator.userAgent.includes('wv') || navigator.userAgent.includes('webview');
        this.checkPlatform();
    }

    ngAfterViewInit() {
      this.checkPlatform();
    }

    chooseWebEnvironment() {
        if (environment.development) {
            this.deepUrlWeb = "http://staging.apiarybuzz.com";
        } else if (environment.production) {
            this.deepUrlWeb = "https://apiarybuzz.com";
        } else if (environment.staging) {
            this.deepUrlWeb = "http://staging.apiarybuzz.com";
        }
    }

    checkPlatform() {
        this.isWebView = navigator.userAgent.includes('wv') || navigator.userAgent.includes('webview');
        var userAgentString = navigator.userAgent.toLowerCase();

        if (userAgentString.indexOf("iphone") > -1 || userAgentString.indexOf("ipod") > -1 || userAgentString.indexOf("ipad") > -1) {
          window.location.href = AppConsts.urlAppStore;
        } else if (navigator.userAgent.match(/android/i)) {
          if (!this.isWebView) {
            window.location.href = this.deepUrlWeb;
          } else {
            setTimeout(function () {
              window.location.href = AppConsts.urlGooglePlay;
            }, 3000);
            window.location.href = this.deepUrlAndroid;
          }
        }
        else if (userAgentString.indexOf("mac") > -1) {
          window.location.href = this.deepUrlWeb;
        } else {
          window.location.href = this.deepUrlWeb;
        }
    }
}
