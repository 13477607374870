import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-two-f-a-congratulation',
  templateUrl: './two-f-a-congratulation.component.html',
  styleUrls: ['./two-f-a-congratulation.component.scss']
})
export class TwoFACongratulationComponent {

  constructor(private _modal: NgbActiveModal,) { }

  public onClose() {
    this._modal.close();
  }
}
