import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiUrl } from '../constants/constants';
import { Utilities } from '../../helpers/utilities';

@Injectable()
export class CommonService {
  isSave = false;
  private profileSubject = new Subject<any>();
  private completedInfoSubject = new Subject<any>();
  private onChangeLanguage = new Subject<any>();

  constructor(
    private _u: Utilities
  ) { }

  getOnChangeLanguage(): Observable<any> {
    return this.onChangeLanguage.asObservable();
  }

  sendOnChangeLanguage(lang: any) {
    this.onChangeLanguage.next(lang);
  }

  getProfile(): Observable<any> {
    return this.profileSubject.asObservable();
  }

  sendProfile(data: any) {
    this.profileSubject.next(data);
  }

  getCompletedInfo(): Observable<any> {
    return this.completedInfoSubject.asObservable();
  }

  sendCompletedInfo(value: boolean) {
    this.completedInfoSubject.next(value);
  }

  getConfigLinks(): Observable<any> {
    return this._u.req.get(ApiUrl.configuration);
  }
}
