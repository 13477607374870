import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: 'faq.component.html',
    styleUrls: ['faq.component.scss']
})

export class FAQComponent implements OnInit {

    listFAQ = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    selected: number = -1;
    
    constructor() { }

    ngOnInit() { }
}