import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { PanelUrl } from '../constants/panel-info';
import { Utilities } from '../../helpers/utilities';

@Injectable()
export class PanelInfoService {

    constructor(private _utilities: Utilities) {
    }

    sendCustomConsent(redeemInfo: string, personalInfo: string): Observable<any> {
        const model = {
            consents: [ {
                id: 99,
                content: redeemInfo
              }, {
                id: 100,
                content: personalInfo
              } ]
        };
        return this._utilities.req.post(PanelUrl.customConsent, model);
    }

    getInfoAccount(): Observable<any> {
        return this._utilities.req.get(PanelUrl.getInfoAccount);
    }

    getScreeningQuestions(): Observable<any> {
        return this._utilities.req.get(PanelUrl.screeningQuestions)
    }

    createPanelInfo(data: any): Observable<any> {
        return this._utilities.req.post(PanelUrl.panelInfo, data);
    }

    getPanelInfo(): Observable<any> {
        return this._utilities.req.get(PanelUrl.panelInfo);
    }

    getDefaultProfile(): Observable<any> {
        return this._utilities.req.get(PanelUrl.getDefaultProfile);
    }

    getLangPackageKeyList(): Observable<any> {
        return this._utilities.req.get(PanelUrl.langPackageKeyList);
    }

    getOptionsByKey(key: string): Observable<any> {
        return this._utilities.req.get(PanelUrl.options + key);
    }

    updateSecurityQuestion(data): Observable<any> {
        return this._utilities.req.post(PanelUrl.personalSecurityQuestion, data);
    }
}
