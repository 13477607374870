import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Utilities } from '../../helpers/utilities';

import { RedeemUrl } from '../constants/redeem-url';
import { Subject } from 'rxjs';

@Injectable()
export class RedeemService {
    isLoadPoints$ = new Subject<boolean>();

    constructor(private _u: Utilities) {}

    getAllRedeemList(page: number, per_page: number, sort: string): Observable<any>{
        return this._u.req.get(RedeemUrl.listRedeem+'?type=all'+'&page='+page+'&per_page='+per_page+'&sort='+sort);
    }

    getRedeemableList(page: number, per_page: number, sort: string): Observable<any>{
        return this._u.req.get(RedeemUrl.listRedeem+'?type=available'+'&page='+page+'&per_page='+per_page+'&sort='+sort);
    }

    getRedeemedList(page: number, per_page: number, sort: string): Observable<any>{
        return this._u.req.get(RedeemUrl.listRedeem+'?type=redeemed'+'&page='+page+'&per_page='+per_page+'&sort='+sort);
    }

    getRedeemableDashboard(): Observable<any>{
        return this._u.req.get(RedeemUrl.listRedeemable);
    }

    createRedeem(data: any): Observable<any>{
        return this._u.req.post(RedeemUrl.createRedeem, data);
    }

    getRedeemedDetail(redeem_history_id: number): Observable<any>{
        return this._u.req.get(RedeemUrl.listRedeem+'/'+redeem_history_id);
    }

}
