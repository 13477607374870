import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';

import { ScriptLoaderService } from '../../../helpers/script-loaders.service';

// declare function callRVIDNow(): any;

@Component({
    selector: 'app-relevant-form',
    templateUrl: 'relevant-form.component.html',
    styleUrls: ['relevant-form.component.scss']
})

export class RelevantFormComponent implements OnInit, AfterViewInit {

    @Input() panelistID: string;
    @Output() onSubmit = new EventEmitter();

    @ViewChild('inputCompleted') inputCompletedRef: ElementRef;

    clientId: string = 'FC0B2642-E386-11E9-8512-12C4BF96CE94';
    surveyId: string;

    constructor(
        private _scriptLoaderService: ScriptLoaderService
    ) { }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this._scriptLoaderService.loadScripts('body', [
            'https://d3op16id4dloxg.cloudfront.net/RelevantID4.js',
            'assets/js/relevant.js'
        ]);

        fromEvent(this.inputCompletedRef.nativeElement, 'change').subscribe(res => {
            console.log('fromEvent', res)
        })

        this.inputCompletedRef.nativeElement.addEventListener('change', event => {
            console.log('addEventListener', event)
        })
    }

    handleSubmitRVID(): void {
      this.onSubmit.emit({ surveyId: this.surveyId, });
      this.inputCompletedRef.nativeElement.value = 0;

      // setTimeout(() => {
      //   const value = this.inputCompletedRef.nativeElement.value;
      //   this.onSubmit.emit({
      //     surveyId: this.surveyId,
      //     relevantResponse: value ? this._getRelevantFormResponse() : null
      //   });}, 5000);

        // trigger call RVID
        // callRVIDNow();
    }

    private _getRelevantFormResponse(): any {
        return {
            RVid: this._getValueInputById('RVid'),
            isNew: this._getValueInputById('isNew'),
            Score: this._getValueInputById('Score'),
            GeoIP: this._getValueInputById('GeoIP'),
            Country: this._getValueInputById('Country'),
            OldId: this._getValueInputById('OldId'),
            OldIDDate: this._getValueInputById('OldIDDate'),
            FirstId: this._getValueInputById('FirstId'),
            FirstIDDate: this._getValueInputById('FirstIDDate'),
            CIDFlag: this._getValueInputById('CIDFlag'),
            TIDFlag: this._getValueInputById('TIDFlag'),
            CompleteFlag: this._getValueInputById('CompleteFlag'),
            CompleteDate: this._getValueInputById('CompleteDate'),
            ScreenoutFlag: this._getValueInputById('ScreenoutFlag'),
            ScreenoutDate: this._getValueInputById('ScreenoutDate'),
            TotalCompletes: this._getValueInputById('TotalCompletes'),
            CSOPanelistID: this._getValueInputById('CSOPanelistID'),
            CSOPanelistIDDate: this._getValueInputById('CSOPanelistIDDate'),
            CSOPanelistIDStatus: this._getValueInputById('CSOPanelistIDStatus'),
            Domain: this._getValueInputById('Domain'),
            FraudProfileScore: this._getValueInputById('FraudProfileScore'),
            FPF1: this._getValueInputById('FPF1'),
            FPF2: this._getValueInputById('FPF2'),
            FPF3: this._getValueInputById('FPF3'),
            FPF4: this._getValueInputById('FPF4'),
            FPF5: this._getValueInputById('FPF5'),
            FPF6: this._getValueInputById('FPF6'),
            FPF7: this._getValueInputById('FPF7'),
            FPF8: this._getValueInputById('FPF8'),
            FPF9: this._getValueInputById('FPF9'),
            FPF10: this._getValueInputById('FPF10'),
            FPF11: this._getValueInputById('FPF11'),
            FPF12: this._getValueInputById('FPF12'),
            FPF13: this._getValueInputById('FPF13'),
            FPF14: this._getValueInputById('FPF14'),
            FPF15: this._getValueInputById('FPF15'),
            FraudFlagCount: this._getValueInputById('FraudFlagCount'),
            RVIDHash2: this._getValueInputById('RVIDHash2'),
            IPAddress: this._getValueInputById('IPAddress'),
            isMobile: this._getValueInputById('isMobile'),
            deviceTime: this._getValueInputById('deviceTime'),
            timeZoneDiff: this._getValueInputById('timeZoneDiff'),
            rvid_pt: this._getValueInputById('rvid_pt'),
            udf1: this._getValueInputById('udf1'),
            IPStatus: this._getValueInputById('IPStatus'),
            RVIDPanelistCount: this._getValueInputById('RVIDPanelistCount'),
        };
    }

    private _getValueInputById(id: string): string {
        const element = document.getElementById(id) as HTMLInputElement;

        return element ? element.value : null;
    }
}
