export class StaticPageUrls {
    public static readonly ForgotPassword = "/api/v1/forgot-password";
    public static readonly validToken = "/api/v1/token";
    public static readonly resetPassword = "/api/v1/reset-password";
}

export const VerificationMethod = {
    BySecurityQuestion: 'BySecurityQuestion',
    ByCode: 'ByCode',
    ByPartialUserCode: 'PartialUserCode'
}