import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RedeemService } from '../../services/redeem.service';
import { RedeemDetailModalComponent } from '../redeem-detail-modal/redeem-detail-modal';
import { RedeemedDetailModalComponent } from '../redeemed-detail-modal/redeemed-detail-modal';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'redeem-item',
  templateUrl: 'redeem-item.component.html',
  styleUrls: ['redeem-item.component.scss']
})
export class RedeemItemComponent implements OnInit {

  @Input() data: any;
  @Input() isRedeemed: boolean = false;

  constructor() {
  }

  ngOnInit() {
    // this.endDate = moment(this.data['end_date']).format('DD-MM-YYYY');
  }

  returnURLImage() {
    return "url(" + this.data.image + ")";
  }
}
